import React, { useEffect, useState } from 'react'
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import './App.css';
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import { getPicture, getProfile, uploadPictureD_id, createfollowingChat, createOrganization, getHairDresser, discussWithChat, startChat } from "./api/service";
import { appLanguage, titleNormalized } from "./select";
import { Langage, LangageHair, AppName } from "./language";
import { getSummary } from './api/service-hair.js';
import { resetLocalStorageKeys } from './utils/helper.js';
import Welcome from './components/welcome/welcome.jsx';
import actionProvider from "./bot/ActionProvider.js";
import VideoIntro from "./widgets/videoIntro/videoIntro";
import {getChatbot} from "./api/service-mca";
import Box from "@mui/material/Box";

function App() {

  const [idOrganization, setIdOrganization] = useState(null);
  const [showMute, setShowMute] = useState(false);
  const [start, setStart] = useState(false);
  const [appReady, setAppReady] = useState(false);
  const [chatReady, setChatReady] = useState(false);

  const handleReady = (flag) => {
    setChatReady(flag)
  }

  const getText = (key) => {
    return Langage[key][appLanguage];
  }
  const getTextApp = (key) => {
    return AppName[key][appLanguage];
  }

  const getAppName = () => {
    return getTextApp(titleNormalized);
  }

  let id;
  if (titleNormalized==='coiffirst-rdv') {
    id = '2501242';
  } else {
    id ='2501243';
  }

  useEffect(() => {
    const fetchData2 = async () => {
      let data
      try {
        const number = parseInt(id, 10);
        data = await createOrganization({ galien_user_id: number, app: titleNormalized, group_id: 4 })
        localStorage.setItem('organizationId', data.id);
        setIdOrganization(id)

        // let res = await createfollowingChat({ app: titleNormalized, galien_id: id, chat_id: null, advisor: '' });
        // localStorage.setItem('followingCactusId', res.id);
      } catch (error) {
        console.error(error)
      }

    }

    const fetchData = async () => {
      try {
        document.title = getAppName();
        const toRemove = ['chatId', 'followingCactusId']
        resetLocalStorageKeys(toRemove);

        let id; // coiffirst-rdv
        if (titleNormalized==='coiffirst-rdv') {
          id = 60;
        } else if(titleNormalized==='coiffirst-coupon') {
          id = 61;
        }

        const data = await getChatbot(id);

        if (data.id) {
          localStorage.setItem('chatbot', JSON.stringify(data));
          if (titleNormalized==='coiffirst-rdv') {
            setChatReady(true)
          }
          await fetchData2();
        }
        console.log('chat initialised');

      } catch (error) {
        console.error(error);
      }
    };

    if(start)
      fetchData();

  }, [start])

  useEffect(() => {

    const getAvatar = async (id) => {
      localStorage.removeItem('avatar');
      localStorage.removeItem('video_intro');
      localStorage.removeItem('ai_video_duration');
      localStorage.removeItem('ai_text');
      localStorage.removeItem('mute');
      if (id) {
        try {
          const profile = await getProfile(id);
          if (profile) {
            if (profile.infos && profile.infos.ai_setting) {
              localStorage.setItem('avatar', profile.infos.ai_setting.ai_avatar);
              localStorage.setItem('video_intro', profile.infos.ai_setting.ai_video);
              localStorage.setItem('video_text', profile.infos.ai_setting.ai_text);
              localStorage.setItem('ai_video_duration', (((profile.infos.ai_setting.ai_video_duration || 0) * 1000)-500).toString());
              if(titleNormalized==='coiffirst-coupon') {
                setShowMute(true)
              }
              setStart(true);
            }
            if(profile.name) {
              document.querySelector('.react-chatbot-kit-chat-header').innerHTML = profile.name;
            }
          }
        } catch {

        }
      }
    }
    getAvatar(id);
  }, [id]);

  const setSoundAction = (action) => {
    setShowMute(false);
    if(action === 1){
      localStorage.setItem('mute', 'false');
      setAppReady(true)
    } else {
      localStorage.setItem('mute', 'true');
      setChatReady(true);
    }
  }

  return (
    <div className="App">
      { showMute && <div style={{backgroundImage: 'linear-gradient(to bottom, transparent 0, #E1C5FF 54%, #DCC5FF 100%)', height: '100vh'}}>
        <Box sx={{width: 320, height: 250, margin: 'auto', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
          <div className={'txt'} style={{fontSize: '26px'}}>Avant de commencer acceptez-vous d’activer le son ?</div>
          <div className={'chois-btns'}>
            <button onClick={() => setSoundAction(1)}>Oui
            </button>
            <button onClick={() => setSoundAction(2)}>Non
            </button>
          </div>
        </Box>
      </div>}
      {appReady && <VideoIntro handleReady={handleReady}></VideoIntro> }
      {chatReady && <Chatbot
        config={config}
        headerText={getAppName()}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText={getText('placeholder')}
        validator={(msg) => msg.length > 0}
      />}
      <div className={'chatId'}>
        {localStorage.getItem('chatIdInfos')}
      </div>
    </div>
  );
}

export default App;


